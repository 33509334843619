'use strict'

import * as _ from 'lodash'

const MultiCheckWidget = {
  props: ['options', 'defaultValue'],
  data () {
    return {
      value: null
    }
  },
  mounted: function () {
    if (this.defaultValue) {
      this.value = this.defaultValue
    }
  },
  methods: {
    clear: function () {
      this.value = ''
      try {
        this.$refs.search.focus()
      } catch (e) {
        console.warn(e)
      }
    },
    confirmChange: function(e, item) {
      if (item._activeCheckedAlert && e.target.checked) {
        e.target.checked = window.confirm(
          this.$t(item._checkedMessage, { name: item._label })
        )
      }
      return e.target.checked
    },
    resetDefaultValue: function () {
      this.value = this.defaultValue
    },
    showable: function (item) {
      return this.searchResultIds.includes(item.id)
    },
    preventSubmit: function (e) {
      e.preventDefault()
      e.stopPropagation()
    },
    update: _.debounce(function (e) {
      this.value = e.target.value
    }, 300)
  },
  computed: {
    items: function () {
      return Object.entries(this.options || {})
        .map(function ([k, item]) {
          item.id = k

          return item
        })
    },
    searchParts: function () {
      let value = String(this.value || '')
      if (value.match(/^\/.+\/$/)) {
        try {
          return [new RegExp(value.slice(1, -1))]
        } catch (e) {
          console.debug(e.message)
          return [/.*/]
        }
      }

      return value.split(' + ').map(function (part) {
        return _.escapeRegExp(part.trim())
      }).filter(function (part) {
        return part !== ''
      })
    },
    searchRegex: function () {
      let parts = this.searchParts

      if (this.searchParts.length === 1 && this.searchParts[0].constructor.name === 'RegExp') {
        return this.searchParts[0]
      }

      return (parts.length === 0)
        ? null
        : new RegExp('.*' + parts.join('.*') + '.*', 'i')
    },
    searchResult: function () {
      let regex = this.searchRegex
      if (!regex) {
        return this.items
      }

      return this.items.filter(function (item) {
        return Object.values(item).some(function (value) {
          return typeof value === 'string' && value.match(regex)
        })
      })
    },
    searchResultIds: function () {
      return this.searchResult.map(function (item) {
        return item.id
      })
    }
  }
}

export {
  MultiCheckWidget
}
