'use strict'

import '../sass/app.scss'
import { appify } from '@rohl/assets'
import { route } from './ziggy'

appify(route)

jQuery(document).ready(function () {
    $('#export').click(function () {
        $('#event-form').attr('action', '/events/export');
    });
    $('#search').click(function () {
        $('#event-form').attr('action', '/events');
    });
});
