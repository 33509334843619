/**
 * @see https://wiki.openstreetmap.org/wiki/Tile_servers
 *
 * @type {object}
 */
const urls = {
  osm: [
    '//{s}.tile.osm.org/{z}/{x}/{y}.png'
  ],
  standard: [
    '//a.tile.openstreetmap.org/{z}/{x}/{y}.png',
    '//b.tile.openstreetmap.org/{z}/{x}/{y}.png',
    '//c.tile.openstreetmap.org/{z}/{x}/{y}.png'
  ],
  wikimedia: [
    '//maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png'
  ],
  cartodb: [
    '//cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
  ],
  hotosm: [
    '//a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
    '//b.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'
  ],
  frosm: [
    '//a.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
    '//b.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
    '//c.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png'
  ]
}

const sample = function (items) {
  return items[~~(items.length * Math.random())]
}

const get = function (key) {
  return sample(urls[key])
}

export { urls, get }
