'use strict'

const Accounts = {
  data () {
    return {
      // status, on start.
      allChecked: false
    }
  },
  methods: {
    selectAll () {
      let refs = Object.entries(this.$refs)
      let allChecked = !this.allChecked

      this.allChecked = allChecked // invert status immediately
      refs.forEach(function (v) {
        let input = v[1]
        if (!v[0].match(/^accounts\[/)) {
          return
        }

        input.computedValue = allChecked ? input.trueValue : false
      })
    }
  }
}

export {
  Accounts as AccountsComponent
}
