'use strict'

const Upload = {
  data () {
    return {
      file: {}
    }
  },
  methods: {
    syncInputFile(file, inputname) {
      const fi = document.getElementsByName(inputname)
      if (fi.length >= 1) {
        const fl = new DataTransfer()
        fl.items.add(file)
        fi[0].files = fl.files
      }
    }
  }
}

export {
  Upload as UploadComponent
}
