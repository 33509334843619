'use strict'

/**
 * ```js
 * module.exports = {
 * context: __dirname,
 *   node: {
 *     __filename: true
 *   }
 * }
 * ```
 */

import { Map as MapComponent } from './components/map'

export { MapComponent }
