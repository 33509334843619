'use strict'

import { MultiCheckWidget } from './multi_check_widget'

const MultiRadioWidget = {
  extends: MultiCheckWidget
}

export {
  MultiRadioWidget
}
