'use strict'

const Export = {
  props: {
    url: {
      type: String,
      default: ''
    },
    limit: {
      type: String,
      default: '1048576'
    }
  },
  data: function () {
    return {
      urlInput: this.url,
      limitInput: this.limit
    }
  },
  computed: {
    valid: function () {
      return !!(this.fullUrl)
    },
    fullUrl: function () {
      if (['', false, null].includes(this.urlInput)) {
        return false
      }

      // TODO support more complex URL construction
      return `${this.urlInput}?limit=${this.limitInput}`
    }
  },
  mounted: function () {
    let urlParams = new URLSearchParams(window.location.search)

    if (this.$attrs['native-url']) {
      this.urlInput = this.$attrs['native-url']
    }
  }
}

export {
  Export as ExportComponent
}
