const tilesType = 'frosm'

const center = [46.52863469527167, 2.43896484375]

const icon = {
  iconUrl: '/images/l-marker.svg',
  iconSize: [32, 32],
  iconAnchor: [16, 16],
  className: 'icon'
}

/** @see https://github.com/Leaflet/Leaflet.markercluster#all-options */
const clusterOptions = {
  showCoverageOnHover: true,
  spiderfyOnMaxZoom: true,
  zoomToBoundsOnClick: true
}

const mapOptions = {
  minZoom: 2,
  zoomSnap: 0.5
}

export { tilesType, center, icon, mapOptions, clusterOptions }
