'use strict'

import { latLng, icon, latLngBounds } from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon, LGeoJson } from 'vue2-leaflet'
import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster'
import * as tiles from './map/tiles'
import * as settings from './map/settings'
import Vue from 'vue'

const DMap = {
  extends: LMap,
  props: {
    zoom: {
      default: 5
    },
    center: {
      default: () => latLng(...settings.center)
    },
    maxBounds: {
      type: Object,
      default() {
        return latLngBounds(latLng(-90, -180), latLng(90, 180))
      }
    },
    options: {
      type: Object,
      default() {
        return settings.mapOptions
      }
    }
  }
}

const DTileLayer = {
  extends: LTileLayer,
  props: {
    url: {
      default: tiles.get(settings.tilesType)
    }
  }
}

const DMarkercluster = {
  extends: Vue2LeafletMarkercluster,
  props: {
    options: {
      default: () => settings.clusterOptions
    }
  }
}

const DMarker = {
  extends: LMarker,
  props: {
    icon: {
      default() {
        return icon(settings.icon)
      }
    }
  }
}

const Map = Vue.component('rohl-map', {
  template: `
    <l-map
        :zoom="zoom"
        :center="center">
    <l-tilelayer :attribution="attribution"></l-tilelayer>
    <l-marker-cluster>
      <l-marker
          v-if="geojson"
          v-for="setup in geojson.features.filter(feature => feature.geometry.type==='Point')"
          :key="setup.id"
          :lat-lng="setup.geometry.coordinates">
        <l-popup>
          <div>
            <a :href="baseurl + '/setups/' + setup.id">
              {{setup.properties.name}}<br/>
              {{setup.properties.park_name}} / {{setup.properties.organization_name}}<br/>
              {{setup.properties.address}}
            </a>
          </div>
        </l-popup>
      </l-marker>
    </l-marker-cluster>
    </l-map>    
  `,
  components: {
    'l-map': DMap,
    'l-tilelayer': DTileLayer,
    'l-marker': DMarker,
    'l-popup': LPopup,
    'l-tootltip': LTooltip,
    'l-icon': LIcon,
    'l-marker-cluster': DMarkercluster,
  },
  props: {
    baseurl: '',
    setup_id: null,
    settings: null
  },
  data() {
    return {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap yvan</a> contributors',
      geojson: {features: []},
      center: this.settings ? JSON.parse(this.settings).center : settings.mapOptions.center,
      zoom: this.settings ? JSON.parse(this.settings).zoom : 5
    }
  },
  async created () {
    const response = await fetch(this.baseurl + "/map/geojson?setup_id=" + this.setup_id)
    this.geojson = await response.json()
  }
})

export { Map }
