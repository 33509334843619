'use strict'

const OrderDocumentsListing = {
  data () {
    return {
      destroyFormRef: 'order_document.destroy.form',
      destroyInputRef: 'order_document.destroy.input'
    }
  },
  computed: {
    destroyForm: function () {
      return this.$root.find(this.destroyFormRef)
    },
    destroyInput: function () {
      return this.$root.find(this.destroyInputRef)
    }
  },
  methods: {
    confirm: function (message) {
      return window.confirm(this.$i18n.t(message))
    },
    destroy: function (uuid) {
      if (this.confirm('message.order_documents.confirm_deletion')) {
        this.destroyInput.value = uuid
        this.$root.openLoading()
        this.destroyForm.submit()
      }
    }
  }
}

export {
  OrderDocumentsListing as OrderDocumentsListingComponent
}
