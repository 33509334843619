/* Forms
 * --------------------------------------------------------------- */
import { Appifier } from '@nx/appifier'
import { layout } from './app/layout'
import Buefy from 'buefy'
import { TitleDirective } from '@nx/vue-common'
import { components } from './components'
import { i18n } from './i18n'

/**
 * @param {object} route
 * @returns {*}
 *
 * Sample of use:
 *
 * ```
 * 'use strict'
 *
 * import '../sass/app.scss'
 * import { appify } from '@nx/assets'
 * import { route } from './ziggy'
 *
 * appify(i18n, route)
 * ```
 */
const appify = function(route) {
  let appifier = new Appifier({
    id: 'app',
    data: {
      isLoading: false
    },
    methods: {
      find (ref) {
        let res = this.$refs[ref]

        if (!res) {
          console.warn(`Can not find: ${ref}`)
        }

        return this.$refs[ref]
      },
      openLoading () {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        }, 10 * 1000)
      },
      impersonate (identifier) {
        let url = route('impersonate:take', { identifier }).url()
        this.openLoading()
        window.location.replace(url)
      }
    },
    i18n: i18n,
    directives: {
      title: TitleDirective
    },
    plugins: [
      [Buefy]
    ],
    components: components,
  })

  appifier.appify()
  layout()
}

export { appify }

