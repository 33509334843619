import {
  DatetimeComponent,
  NumberComponent
} from '@nx/vue-common'
import { MapComponent } from '@rohl/map'
import {
  SearchComponent,
  ExportComponent,
  AccountsComponent,
  UploadComponent,
  OrderDocumentsListingComponent
} from './vue/components'
import {
  DropdownWidget as DropdownWidgetComponent,
  MultiRadioWidget as MultiRadioWidgetComponent,
  MultiCheckWidget as MultiCheckWidgetComponent
} from './app/components'
import { CardModal, Modal } from '@nx/modal'

const components = {
  'modal': Modal,
  'card-modal': CardModal,
  'date-time': DatetimeComponent,
  'rohl-map': MapComponent,
  'rohl-order_documents_listing': OrderDocumentsListingComponent,
  'number': NumberComponent,
  'rohl-search': SearchComponent,
  'rohl-accounts': AccountsComponent,
  'rohl-upload': UploadComponent,
  'rohl-export': ExportComponent,
  'dropdown-widget': DropdownWidgetComponent,
  'multicheck-widget': MultiCheckWidgetComponent,
  'multiradio-widget': MultiRadioWidgetComponent
}

export { components }
