import { Ziggy } from './ziggy'
import _route from './route'

const location = window.location

Object.assign(Ziggy, {
  baseUrl: '/',
  baseProtocol: location.protocol,
  baseDomain: location.host
})

/**
 * @returns {Router}
 * @see https://github.com/tightenco/ziggy
 */
const route = function (name, params, absolute, customZiggy) {
  customZiggy = customZiggy || Ziggy

  return _route(name, params, absolute, customZiggy)
}

export { route, Ziggy }
