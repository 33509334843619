'use strict'

/**
 * globals require
 * @see https://kazupon.github.io/vue-i18n/
 * @see https://kazupon.github.io/vue-i18n/started.html#javascript
 */

const locales = ['fr', 'en', 'de', 'es']

const dateTimeFormats = (function () {
  let res = {}
  locales.forEach(function (code) {
    res[code] = require(`./datetime/${code}.json`)
  })

  return res
})()

const messages = (function () {
  let res = {}
  locales.forEach(function (code) {
    res[code] = require(`./messages/${code}.json`)
  })

  return res
})()

/**
 * @type {string}
 */
const locale = document.documentElement.lang

/**
 * @type {string}
 */
const fallbackLocale = locales[0]

/**
 * @see https://kazupon.github.io/vue-i18n/
 * @see https://kazupon.github.io/vue-i18n/started.html#javascript
 *
 * @type {{messages: {en, fr}, fallbackLocale: string, locale: string, dateTimeFormats: {en, fr}}}
 */
const i18n = { locale, messages, dateTimeFormats, fallbackLocale }

export {
  i18n,
  locale,
  messages,
  dateTimeFormats,
  fallbackLocale
}
