'use strict'

const DropdownWidget = {
  methods: {
    destroy: function (param = 'destroy') {
      let target = this.$refs[param]

      if (target) {
        let message = target.dataset.confirmMessage
        // eslint-disable-next-line no-undef
        if (confirm(message)) {
          target.submit()
        }
      }
    }
  }
}

export {
  DropdownWidget
}
