'use strict';

(function () {
  /* globals require, jQuery */
  window.$ = window.jQuery = require('jquery')

  require('jquery.are-you-sure')
}())

const layout = function () {
  jQuery(document).ready(function () {
    let $ = jQuery

    $('form.are-you-sure').areYouSure({
      message: 'Voulez-vous quitter la page sans sauvegarder ?'
    })

    // layout ---------------------------------------------------------
    // Check for click events on the navbar burger icon
    $('.navbar-burger').click(function () {
      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      $('.navbar-burger').toggleClass('is-active')
      $('.navbar-menu').toggleClass('is-active')
    })
    // Check for click events on the navbar logout button
    $('.is-logout-button').click(function () {
      $('#logout-form').submit()
    });
    // notifications --------------------------------------------------
    (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
      let $notification = $delete.parentNode
      $delete.addEventListener('click', () => {
        $notification.parentNode.removeChild($notification)
      })
    })
  })
}

export { layout }
