'use strict'

const Search = {
  props: {
    url: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      urlInput: this.url,
      valueInput: this.value
    }
  },
  computed: {
    valid: function () {
      return (this.valueInput !== '' && this.urlInput !== '')
    },
    fullUrl: function () {
      return this.valid ? `${this.urlInput}?q=${this.valueInput}` : null
    }
  },
  mounted: function () {
    let urlParams = new URLSearchParams(window.location.search)

    if (this.$attrs['native-url']) {
      this.urlInput = this.$attrs['native-url']
    }

    if (urlParams.has('q')) {
      this.valueInput = urlParams.get('q')
    }
  }
}

export {
  Search as SearchComponent
}
